.BulkModalImport {
    max-width: 70%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.cardDesign {
    border-radius: 1.25rem !important;
    border-color: #007bff;
}

.single-field-editor-card-wrap {
    position: relative;  
}
  
.bulk-wrap {
    border-radius: 5px;
    padding: 0px;
}

.rt-td {
    text-align: center;
}

.rt-thead .rt-th {
    background: #007bff;
    color: white;
}

.ReactTable.-striped .rt-tr.-odd {
    background-color: #dce7f2
}