.promptsCard {
    max-width: 90%;
    min-width: 90%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.cardDesign {
    border-color: #ebecec !important;
    background-color: #ebecec !important;
}

.promptsTitle {
    line-height: 2;
    display: flex;
    width: 100%;
    height: 10vmin;
}

.promptsCardBody {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.promptsprogressImage {
    width: 12%;
    float: right;
    margin-bottom: -5px;
}

.single-field-editor-card-wrap {
    position: relative;  
}
  
.prompts-wrap {
    border: 1px solid #ddddddff;
    border-radius: 5px;
    padding: 0px;
    height: 35vmin;
}

.rt-td {
    text-align: center;
}

.rt-thead .rt-th {
    background: #007bff;
    color: white;
}

.ReactTable.-striped .rt-tr.-odd {
    background-color: #dce7f2
}

.titleBadgePrompts {
    background-color: #ebecec !important;
    margin-left: 0px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 3.5vmin !important;
    font-weight: 500;
    color: #061e45;
    margin-top: 5px;
    float: left;
  }

  .CardBodyPrompts{
    width: 100% !important;
    margin-left: -10px !important;
    margin-top: -25px !important;
  }

  .AddButtonPrompts{
    background-color: #061e45 !important;
    border-color: #061e45 !important;
    font-size: 2vmin;
  }

  .SubmitButtonPrompts{
    margin-top: 5px;
    float: right;
    background-color: #061e45 !important;
  }

  .BrowseButtonPrompts{
    padding-left: 5%;
    width: 20%;
  }

  .ImageMapper{
    float: right;
  }

  .divPrompts{
    display: inline-block;
    width: 100%;
    margin-left: -20px;
  }
  .checkbox{
    text-align: "center";
    vertical-align: "middle";
    position: relative;
  }