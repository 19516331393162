.preloader-local-background {
  z-index: 100;
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;  
  background-color: white;
  opacity: 0.8;
}

.preloader-local-outer {
  z-index: 101;
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;      
}

.preloader-local-inner {  
  position: absolute;  
  top: 25%;
  width: 100%;
  text-align: center;    
}

.preLoaderLocal-logo {
  width: 50px;
  height: 50px;
}