.navBottom {
    background-color: midnightblue;
    max-width: fit-content;
    left: 18%;
    padding: 0px;
    padding-left: 10px;
    padding-right: 10px;
}

.navItemsBottom {
    padding-left: revert;
}

.bottomButtons {
    border: none;
    background: none;
    color: white;
}

.btmButtons {
    border: none;
    background: none;
    color: white;
    opacity: 0.5;
}

.infobuttons {
    margin-left: 19px;
    margin-right: 19px;
}

@media only screen and (max-width: 1200px) {
    .infobuttons {
        margin-left: -25px;
    }
}