.thirdCard {
    margin-top: 90px !important;
}

.sliderButton {
    background-color: rgb(235, 236, 236);
    width: 50px;
    height: 50px;
    color: #061e45 !important;
}

.header{
    margin-top: 15px;
    margin-left: 25vmin;
    color: #061e45;
    font-size: 4vmin;
}

.containerCardSection {
    background-color: rgb(235, 236, 236);
    max-width: 70%;
    min-width: 70%;
    position: absolute;
    top: 3%;
    left: 15%;
    right: 0;
    bottom: 0;
    margin: auto;
    min-height: 75%;
    max-height: 75%
}