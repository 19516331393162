.container {
  margin-top: 20px;
}

.purecloud-login-card {  
  height: 460px;
  padding: 0px;
}

.purecloud-login-iframe {
  width: 100%;
  height: 100%;
  border: 0px none;
}