.event-list-viewer-main {
  width: 90%;
  min-width: 90%;
  max-width: 90%; 
}

.ReactTable .rt-th, .ReactTable .rt-td {
  white-space: unset;
}

