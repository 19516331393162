.badge{
    margin-top: 10px;
    margin-left: 10vmin;
    font-size: 3.5vmin;
    font-weight: normal;
    background-color: '#061e45'
}

.badgetext{
    animation: 5s fadeIn;
    animation-fill-mode: forwards;
    visibility: hidden;  
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 0.25;
    }
    50% {
        opacity: 0.5;
    }
    75% {
        opacity: 0.75;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }
}

.containerCard {
    background-color: rgb(235, 236, 236);
    width: 180vmin !important;
    height: 77.5vmin;
    padding-top: 15px;
    padding-bottom: 10px;
}

.firstrowCard {
    padding-bottom: 10px;
}

.secondrowCard {
    padding-top: 10px;
    padding-bottom: 0px;
}

.cardImage {
    padding-top: 25px;
    width: 33vmin;
    align-self: center;
}

.cardBody {
    align-self: left;
    width: 250px;
    height: 110px;
    padding-bottom: 0px;
    padding-top: 10px;
    padding-left: 30px;
}

.cardText{
    font-size: 90%;
}

.cardBar {
    background-color: #ffffff;
    cursor: pointer;
    transition:transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    border-radius: 30px;

}

.cardBar:hover {
    transform:scale(1.1);
}

.checkmark {
    height: 0px;
    font-size: x-large;
    text-align: right;
    padding-right: 20px;
}

.tickMarkImage {
    width: 50px;
}
