.designFlowsInfoModal {
    max-width: 50%;
}

/* Phone Pad */
.phone-body
{
    padding: 0;
    color: #333;
    background-color: #fff;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.phone
{
    margin-top: 15px;
    background: #fff;
    margin: 0 auto;
    padding: 20px;
    max-width: fit-content;
}

.num-pad
{
    padding-left: 15px;
}

.span4
{
    width: 80px;
    float: left;
    margin: 0 8px 10px 8px;
}

.num
{
    border: 1px solid #9e9e9e;
    -webkit-border-radius: 999px;
    border-radius: 999px;
    -moz-border-radius: 999px;
    height: 80px;
    background-color: #fff;
    color: #333;
    cursor: pointer;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
}
.num:hover
{
    background-color: #ff4f1f;
    color: #fff;
    transition-property: background-color .2s linear 0s;
    -moz-transition: background-color .2s linear 0s;
    -webkit-transition: background-color .2s linear 0s;
    -o-transition: background-color .2s linear 0s;
}

.txt
{
    font-size: 30px;
    text-align: center;
    margin-top: 15px;
    font-family: 'Lato' , sans-serif;
    line-height: 30px;
    color: #333;
}

.phone-small
{
    font-size: 15px;
}

.phone-p
{
    margin: 0;
    padding: 0 0 10px 0;
    line-height: 20px;
}

.txt-success {
    color: green;
}