.fixed-top {
  z-index: 200;
}

.navbar-logo-app {
  width: 29px;
  height: 26px;
  margin-right: 10px;
}

.navbar-version-info {
  opacity: 0.3;
}

img.user-picture {
  border-radius: 50%;
  border: 2px solid #ff4f1f;
  height: 38px;
  width: 38px;
}

.btn-disabled,
.btn-disabled[disabled] {
  opacity: .4;
  cursor: default !important;
  pointer-events: none;
}

.hyperLinks {
  top: 56px;
  background-color: white !important;
  z-index: 1;
}

.menu{
  align-items:right;
  padding-bottom: 37px;
  line-height: 18px;
  font-size: 50px;
  color: white;
}


.navbrand{
  padding-top: 10px;
}

.navbar{
  background-color: #061e45;
  padding-bottom: 0px;
  padding-top: 0px;
  padding-right: 5px;
  padding-left:10px;
  max-height:70px;
}