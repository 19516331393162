

.home-main-area {
  position: absolute;
  top: 56px;
  right: 0px;
  bottom: 0px;
  left: 64px;
  overflow-y: auto;
  padding: 15px;  
}
