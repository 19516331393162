.libraryCard {
    max-width: 90%;
    min-width: 90%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.cardDesign {
    border-color: #ebecec !important;
    background-color: #ebecec !important;
}

.libraryTitle {
    line-height: 2;
    display: flex;
    width: 100%;
    height: 10vmin;
}

.libraryCardBody {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.libraryprogressImage {
    width: 24%;
    float: right;
    margin-bottom: -5px;
}

.FieldSet {
    border: 2px solid;
    padding: 0.75em;
}

.LegendClass {
    width: auto;
    padding: 5px;
    margin: auto;
    font-size: 2.5vmin;
}

.custom-file-upload {
    border: 1px solid transparent;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    font-weight: 400;
    font-size: 1rem;
    border-radius: .25rem;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.titleBadgeDesignFlows {
    background-color: #ebecec !important;
    margin-left: 0px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 3.5vmin !important;
    font-weight: 500;
    color: #061e45;
    margin-top: 5px;
    float: left;
  }

  .CardBodyLibFlows{
    width: 100% !important;
    margin-left: -10px !important;
    margin-top: -30px !important;
  }

  .SubmitButtonDesignFlows{
    margin-top: -12px;
    float: right;
    background-color: #061e45 !important;
  }

  .ImageMapper{
    float: right;
  }

  .divLibFlows{
    display: inline-block;
    width: 100%;
    margin-left: -20px;
  }