.single-field-editor-card-wrap {
    position: relative;  
}

.cardDesign {
    border-color: #ebecec !important;
    background-color: #ebecec !important;
}

.campTypeTitle {
    line-height: 2;
    display: flex;
    width: 100%;
    height: 14vmin;
}

.campTypeCardBody {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.campTypeprogressImage {
    width: 52%;
    float: right;
    margin-bottom: -5px;
}

.campType-wrap {
    border: 1px solid #061e45;
    border-radius: 5px;
    padding: 10px;
}

.campTypeCard {
    max-width: 90%;
    min-width: 90%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.wrapper{
    width:200px;
    padding:20px;
    height: 150px;
    }

.titleBadgeCampType {
    background-color: #ebecec !important;
    margin-left: 0px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 3.5vmin !important;
    font-weight: 500;
    color: #061e45;
    margin-top: 5px;
    float: left;
    }

.CardBodyCampType{
    width: 100% !important;
    margin-left: -10px !important;
    margin-top: -25px !important;
}

.AddButtonCampType{
background-color: #061e45 !important;
border-color: #061e45 !important;
font-size: 2vmin;
}

.SubmitButtonCampType{
margin-top: -12.5px;
float: right;
background-color: #061e45 !important;
font-size: 2vmin;
}

.ImageMapper{
    float: right;
  }

  .divCampTypes{
    display: inline-block;
    width: 100%;
    margin-left: -20px;
  }

.rowAttr {
    margin-bottom: 3px;
}
