.promptsModal {
    max-width: 50%;
}

.prompt-file-upload {
    border: 1px solid transparent;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    font-weight: 400;
    font-size: 1rem;
    border-radius: .25rem;
    color: #fff;
    background-color: #061e45;
    border-color: #061e45;
    text-align: center;
}

.fileuploader * {
    box-sizing: border-box;
}

.fileuploader-input {
    display: block;
    position: relative;
    cursor: pointer;
    padding: 60px 0;
    background: #fff;
    border: 2px dashed #c2cdda;
    border-radius: 14px;
    text-align: center;
}

.drop-active {
    background: #ff8;
    opacity: 0.5;
}

.fileuploader-icon-main {
    font-size: 50px;
    color: #ff4f1f;
}