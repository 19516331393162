.BulkModalExport {
    max-width: 25%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}


.label{
    margin-bottom: 0px;
    align-content: center;
}

.chkbox{
    margin-top: 8px;
    margin-left: 10px;
    height: 15px;
    width: 15px;
    align-content: center;
}