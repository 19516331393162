.single-field-editor-card-wrap {
    position: relative;  
  }

.OrgTitle {
  line-height: 2;
  display: flex;
  width: 100%;
  height: 15vmin;
}

.titleBadge{
  background-color: #ebecec !important;
  margin-left: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 3.5vmin !important;
  font-weight: 500;
  color: #061e45;
  margin-top: 0px;
}

.cardBody1{
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.CardBody2{
  width: 100% !important;
  margin-left: -10px !important;
  margin-top: -25px !important;
}

.supText{
  color: orangered;
}
.cardDesign {
  border-color: #ebecec !important;
  background-color: #ebecec !important;
  margin-top: 5px;
}
  
  .single-field-editor-table-wrap {
    height: 300px;
    overflow-y: scroll;
    border: 1px solid #ddddddff;
    border-radius: 5px;
    padding: 0px;
  }
  
  .single-field-editor-table {
    table-layout: initial;
  }
  
  td.single-field-editor-table {
    word-wrap: break-word;  
  }
  
  td.single-field-editor-button-cell {
    width: 25px;
    color: red;
    cursor: pointer;
    text-align: center;
  }
  
  /* <links> */
  a.single-field-editor-link:link {
    color: black;
  }
  
  a.single-field-editor-link {
    color: black;
  }
  
  a.single-field-editor-link {
    color: black;
  }
  
  a.single-field-editor-link {
    color: black;
  }
  /* </links> */

  .orgCard {
    max-width: 90%;
    min-width: 90%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  .custom-file-upload {
    border: 1px solid transparent;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    font-weight: 400;
    font-size: 1rem;
    border-radius: .25rem;
    color: #fff;
    background-color: #061e45 !important;
    border-color: #061e45 !important;
    text-align: center;
}

.AddButton{
  background-color: #061e45 !important;
  border-color: #061e45 !important;
  float: left;
  font-size: 2vmin;
}

.fileDropCol {
  display: flex;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff
}

.rowHeaderOrg {
  line-height: 12px !important;
  height: 25px !important;
}

tr.rowHeaderOrg th {
  background: #061e45;
  color: white;
  font-size: 14px;
  font-size: 1rem;
}

th {
  background: white;
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

hr.rounded {
  border-top: 20px solid #061e45 !important;
  border-radius: 5px;
}

.card>hr {
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 0px;
}

.progressImage {
  width: 50%;
  float: right;
}

.BrowseButton{
  padding-left: 0%;
  width: 20%;
}

.ImageMapper{
  float: right;
}

.divOrg{
  display: inline-block;
  width: 100%;
  margin-left: -20px;
}
.checkbox{
  text-align: "center";
  vertical-align:middle;
  position: sticky;
}

.buttonRemove {
  background-color: #007bff !important;
  border-radius: 25px !important;
  cursor: pointer;
}

.buttonRemoveDefault {
  background-color: #061e45 !important;
  border-radius: 0px !important;
}

.checkboxHeader{
  width: 7.5%;
  position: sticky !important;
  top: 0 !important;
  z-index: 1 !important;
}