.preloader-background {
  z-index: 300;
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;  
  background-color: white;
  opacity: 0.8;
}

.preloader-outer {
  z-index: 301;
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;      
}

.preloader-inner {  
  position: fixed;  
  top: 40%;
  width: 100%;
  text-align: center;    
}

.preLoader-logo {
  width: 50px;
  height: 50px;
}