.usersModal {
    max-width: 60%;
}

.userInfo-wrap {
    height: 150px;
    overflow-y: scroll;
    border: 1px solid #ddddddff;
    border-radius: 5px;
    padding: 0px;
}

.userInfo-table {
    table-layout: initial;
}

td.userInfo-table {
    word-wrap: break-word;  
}
  
td.userInfo-button-cell {
    width: 25px;
    color: red;
    cursor: pointer;
    text-align: center;
}

.userInfoHeader {
    line-height: 5px;
    text-align: center;
}

tr.userInfoHeader th {
    background: lightgrey;
    color: black;
}
  
th {
    background: white;
    position: sticky;
    top: 0; /* Don't forget this, required for the stickiness */
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}
