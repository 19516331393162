.single-field-editor-card-wrap {
    position: relative;  
}

.cardDesign {
    border-color: #ebecec !important;
    background-color: #ebecec !important;
}

.listTitle {
    line-height: 2;
    display: flex;
    width: 100%;
    height: 16vmin;
}

.listCardBody {
    padding-top: 10px !important;
    padding-bottom: 5px !important;
}

.listprogressImage {
    width: 52%;
    float: right;
    margin-bottom: -5px;
}

.list-wrap {
    border: 1px solid #061e45;
    border-radius: 5px;
    padding: 10px;
}

.listCard {
    max-width: 90%;
    min-width: 90%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.titleBadgeList {
    background-color: #ebecec !important;
    margin-left: 0px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 28px;
    font-weight: 500;
    color: #061e45;
    margin-top: 5px;
    float: left;
  }

.CardBodyList{
width: 100% !important;
margin-left: -10px !important;
margin-top: 5px !important;
}

.ImportButtonList{
background-color: #061e45 !important;
border-color: #061e45 !important;
}

.SubmitButtonList{
margin-top: 5px;
float: right;
background-color: #061e45 !important;
}

.ImageMapper{
    float: right;
    padding-left: 100px;
    margin-top: 10px;
  }

  .divLists{
    display: inline-block;
    width: 100%;
    margin-left: -20px;
  }
