.single-field-editor-card-wrap {
    position: relative;  
}

.cardDesign {
    border-color: #ebecec !important;
    background-color: #ebecec !important;
}

.outWrapTitle {
    line-height: 2;
    display: flex;
    width: 100%;
    height: 16vmin;
}

.outWrapCardBody {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.outWrapprogressImage {
    width: 52%;
    float: right;
    margin-bottom: -5px;
}

.outWrap-wrap {
    border: 1px solid #efeff0 ;
    border-radius: 5px;
    padding: 0px;
}

.outWrapCard {
    max-width: 90%;
    min-width: 90%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.rowHeader {
    line-height: 12px;
    font-size: 2vmin;
  }
  
tr.rowHeader th {
background: #061e45;
color: white;
position: sticky;
z-index: 1;
}

td.wrapCodes{
    background: #061e45 ;
    color: white;
  }
  
tr.items {
    font-size: 80%;
}

.single-field-editor-table-wrap {
    height: 300px;
    overflow-y: scroll;
    border: 1px solid #ddddddff;
    border-radius: 5px;
    padding: 0px;
    position: sticky;
}

.single-field-editor-table {
    table-layout: initial;
}

td.single-field-editor-table {
    word-wrap: break-word;  
}

.titleBadgeWrapCodes {
    background-color: #ebecec !important;
    margin-left: 0px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 3.5vmin !important;
    font-weight: 500;
    color: #061e45;
    margin-top: 5px;
    float: left;
}

.CardBodyWrapCodes{
width: 100% !important;
margin-left: -10px !important;
margin-top: -35px !important;
}

.SubmitButtonWrapCodes{
margin-top: -12px;
float: right;
background-color: #061e45 !important;
}

.ImageMapper{
    float: right;
  }

  .divWrapCodes{
    display: inline-block;
    width: 100%;
    margin-left: -20px;
  }
 
  