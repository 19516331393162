.single-field-editor-card-wrap {
    position: relative;  
}

.cardDesign {
    border-color: #ebecec !important;
    background-color: #ebecec !important;
}

.campTitle {
    line-height: 2;
    display: flex;
    width: 100%;
    height: 14.5vmin;
}

.campCardBody {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.campprogressImage {
    width: 52%;
    float: right;
    margin-bottom: -5px;
}

.camp-wrap {
    border: 1px solid #061e45;
    border-radius: 5px;
    padding: 0px;
}

.campCard {
    max-width: 90%;
    min-width: 90%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.titleBadgeCamp {
    background-color: #ebecec !important;
    margin-left: 0px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 3.5vmin !important;
    font-weight: 500;
    color: #061e45;
    margin-top: 5px;
    float: left;
}

.CardBodyCamp {
    width: 100% !important;
    margin-left: -10px !important;
    margin-top: -10px !important;
}

.SubmitButtonCamp {
    margin-top: -10px;
    float: right;
    background-color: #061e45 !important;
    font-size: 2vmin;
}

.ModalButtonCamp {
    float: right;
    background-color: #061e45 !important;
}

.ImageMapper{
    float: right;
}

.divCamp {
    display: inline-block;
    width: 100%;
    margin-left: -20px;
}

.rowAttrCamp {
    margin-bottom: 5px;
}
